.cover-image-display {
    height: 450px;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}
.imageBorder {
    background-color: white;
    margin-left: 12%;
    margin-right: 12%;
    padding-top: 25px;
    border-radius: 20px;
}
.card-search-image-display {
    height: 240px;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.Image-Display {
    height: 480px;
}

.imagesPageDisplay {
    height: 120px;
    max-width: 120px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    padding: 0%;
    filter: drop-shadow(0px 0px 3px #000000);
}
.imagesPageDisplaySmall {
    height: 50px;
    max-width: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    padding: 0%;
    filter: drop-shadow(0px 0px 3px #000000);
}
.subCardWallGrades {
    text-align: right;
    position: relative;
    top: -122px;
    margin-right: 10px;
    height: 0px;
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    text-shadow: 2px 2px 2px #000, 0 0 2px #000;
}
.subCardWallLogo {
    text-align: left;
    position: relative;
    top: -125px;
    margin-left: 5px;
    height: 0px;
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    text-shadow: 2px 2px 2px #000, 0 0 2px #000;
}
.custom-navbar {
    color: white !important;
    background-image: linear-gradient(#181818, black);
}
.custom-navbar a {
    color: #f3f3f3;
    padding-left: 6px;
    padding-right: 6px;
}
.custom-navbar a:hover {
    background-color: #181818;
    border-radius: 10px;
    padding-left: 6px;
    padding-right: 6px;
    color: #acf06c;
}
.custom-auth{
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 5px;
}

a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.dropdown-menu {
    background-color: #181818;
}
.dropdown-menu a:hover {
    background-color: #000000;
}

.navbar-toggler:focus {
    box-shadow: 0px 0px 3px #acf06c;
}

.footer{
    padding-left: 75px;
    padding-right: 75px;
}
@media (max-width: 600px) {
    .navbar {
        --bs-navbar-brand-font-size: 1rem;
    }
    .css-i4bv87-MuiSvgIcon-root {
        font-size: 1.2rem !important
    }
    .navbar-toggler-icon {
        width: .8em;
        height: .8em;
    }
}